import Axios from 'axios';

/* eslint-disable no-param-reassign */

function serverURL(path) {
  const urlPath = (path.indexOf('/') === 0) ? path : `/${path}`;

  if (window.location.port === '8080') {
    // local development
    return `http://localhost:3000${urlPath}`;
  }

  return `${window.location.origin}${urlPath}`;
}

const API = {
  install(Vue) {
    Vue.prototype.$_serverURL = serverURL;

    Vue.prototype.$_sendEvent = function sendEvent(eventData) {
      const sendEventURL = serverURL('/internal/events');

      console.log(`posting to ${sendEventURL}`);

      return Axios.post(
        sendEventURL,
        eventData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: 1000,
        },
      );
    };
  },
};

export default API;
