import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/specs',
  //   name: 'Specs',
  //   component: () => import('@/views/Specs.vue'),
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
