<template>
  <v-container>
    <v-row>
      <v-col class="mb-4">
        <v-tabs centered v-model="tab">
          <v-tab>About</v-tab>
          <v-tab>Event Tester</v-tab>
          <v-tab>Specs</v-tab>
        </v-tabs>

        <v-tabs-items class="mt-4 pl-3 pr-3" v-model="tab">
          <v-tab-item>
            <about></about>
          </v-tab-item>
          <v-tab-item>
            <event-tester />
          </v-tab-item>
          <v-tab-item>
            <specs></specs>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import About from '@/components/About.vue';
import EventTester from '@/components/EventTester.vue';
import Specs from '@/components/Specs.vue';

export default {
  name: 'Home',

  components: {
    About,
    EventTester,
    Specs,
  },

  data: () => ({
    tab: 0,
  }),
};
</script>
