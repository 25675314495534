<template>
  <v-container>
    <!-- HTML export of Google Docs document below -->

    <p class="c8 title" id="h.hcomra42myoq">
      <span class="c21 c27">SafeNow WebHook-API</span>
    </p>
    <h1 class="c11" id="h.zg2pfgj5lqyh">
      <span class="c18">Introduction</span>
    </h1>
    <p class="c4">
      <span class="c3"
        >SafeNow supports people in danger to reach out for help and to connect
        them with local security if available. Depending on the situation, local
        security will want to escalate to emergency medical services or to the
        police. Doing so via 110 or 112 is far from optimal whenever such
        resources are already on-site, as for emergency medical services at
        larger concerts or both at larger events.</span
      >
    </p>
    <p class="c4">
      <span class="c3"
        >This document describes a first version of a WebHook API for
        integrating SafeNow alarms into external systems interfacing with
        resources like on-site emergency medical services. There is no known
        standard for this and focus was put in a generic mechanism that could
        work for other external resources as well.
      </span>
    </p>
    <p class="c14">
      <span class="c3"
        >SafeNow internally uses GraphQL for its API, but WebHooks are defined
        as plain HTTP-POSTs with mostly JSON as payload and that is what is used
        for the SafeNow WebHook API.</span
      >
    </p>
    <h1 class="c11" id="h.pjj207395tpt">
      <span class="c18">User Stories</span>
    </h1>
    <p class="c4">
      <span class="c3"
        >The following user stories describe the functionality needed for
        integration with external resources such as emergency medical services
        (EMS). We normally keep technical details out of our user stories, but
        this document is created with a first specific partner and therefore we
        add technical details as acceptance criteria.
      </span>
    </p>
    <p class="c4">
      <span class="c3"
        >The following terms are used in the user stories or commonly used in
        the SafeNow context (described in logical order):</span
      >
    </p>
    <p class="c4">
      <span class="c15">User:</span
      ><span class="c3"
        >&nbsp;A person using the SafeNow User App to reach out for help.</span
      >
    </p>
    <p class="c4">
      <span class="c15">Host:</span
      ><span class="c3"
        >&nbsp;A person running a venue (e.g. bar, restaurant, university, bus
        station). This is more of an abstract role and the work described by
        user stories targeting a host may very well be outsourced to a
        subcontractor.
      </span>
    </p>
    <p class="c4">
      <span class="c15">Security Provider:</span
      ><span class="c3"
        >&nbsp;Contracted by a host to run security service on premise of a
        host.</span
      >
    </p>
    <p class="c4">
      <span class="c15">Guard:</span
      ><span class="c3"
        >&nbsp;Employee of a security provider working on premise of a host or
        nearby to secure the property and help users in need.</span
      >
    </p>
    <p class="c4">
      <span class="c15">External Resource:</span
      ><span class="c3"
        >&nbsp;Services like an emergency medical service (EMS) which could be
        integrated on-site for providing additional support.</span
      >
    </p>
    <p class="c4">
      <span class="c15">Resource Capabilities:</span
      ><span
        >&nbsp;Standardized values for services provided by an external
        resource. Currently defined are </span
      ><span>ems</span><span>, </span><span>police</span><span>&nbsp;and </span
      ><span>fire-service.</span
      ><span class="c3"
        >&nbsp;Capabilities could be more fine-grained, but the decision about
        what exactly to provide is done by the operations managers of external
        resources anyways. Guards ask for these capabilities when escalating an
        ongoing alarm.</span
      >
    </p>
    <p class="c4">
      <span class="c15">SafeNow User App:</span
      ><span class="c3"
        >&nbsp;Smartphone and tablet app targeting SafeNow users. It provides an
        interface for creating and joining groups of users, which can be alerted
        when a user is in need. In addition to this private setup, it alerts
        guards when the alarm happens on premise of a SafeNow host. Users can
        optionally provide details about their problems and this could make the
        SafeNow backend escalate to external resources (e.g. the user flags the
        problem as a medical emergency and this is escalated to an emergency
        medical service).</span
      >
    </p>
    <p class="c4">
      <span class="c15">SafeNow Guard App:</span
      ><span class="c3"
        >&nbsp;Smartphone and tablet app, which targets SafeNow guards. It
        provides a user interface for creating geographic areas within the
        perimeter of a host&rsquo;s venue, in which users can alert guards
        through the SafeNow User App. The app also provides administration of
        guards with shift plans and includes a tactical chat for communication
        during normal work and especially while an alarm is going on.</span
      >
    </p>
    <p class="c4">
      <span class="c15">SafeNow Zone:</span
      ><span class="c3"
        >&nbsp;Abstract area that is covered by a Security Provider including
        location, perimeter and maybe a floor number. This can be any kind of
        public or private location like a restaurant, a rooftop bar or e.g. a
        public park. Each area has a unique ID. External resources can register
        their capabilities for one or more areas.</span
      >
    </p>
    <p class="c4">
      <span class="c15">Beacon:</span
      ><span class="c3"
        >&nbsp;Cheap device mostly run by battery, which follows the iBeacon
        protocol to advertise some ID. Used for different use cases like
        interactive ads, device discovery or e.g. by SafeNow to localize a
        user.</span
      >
    </p>
    <p class="c4">
      <span class="c15">Case:</span
      ><span class="c3"
        >&nbsp;When a user feels uneasy or at least when triggering an alarm, a
        case is created as an abstract frame for documenting all further
        activities. Alarms are created within that case and if a user moves
        between locations, new alarms could be triggered within that case. For a
        certain time period, new alarms created after cancellation are seen in
        context of the prior case as well. Cases have a unique ID and a
        state.</span
      >
    </p>
    <p class="c4">
      <span class="c15">Alarm:</span
      ><span class="c3"
        >&nbsp;Created in context of a case when the user triggers an alarm.
        Alarms have a unique ID and a state.</span
      >
    </p>
    <h2 class="c9" id="h.b8bamw3wn9rv">
      <span class="c21 c25">Registration</span>
    </h2>
    <h4 class="c12" id="h.mqarmf1f9tby"><span class="c17">User Story</span></h4>
    <p class="c4">
      <span class="c3"
        >As an external resource, I need to be able to register my service for a
        certain location and a certain time period. I want to register
        capabilities and not e.g. number of people, but I want to provide
        contact details of an on-site operations manager as fallback if
        something goes wrong with our technical integration. I will provide
        SafeNow with a WebHook to call if my service is needed and I expect all
        this to be secure.</span
      >
    </p>
    <h4 class="c12" id="h.odbrckwpp76n">
      <span class="c17">Acceptance Criteria</span>
    </h4>
    <ul class="c28 lst-kix_zh9rzirelwwg-0 start">
      <li class="c0 li-bullet-0">
        <span
          >SafeNow provides an externally reachable HTTPS endpoint which
          supports POST messages. This endpoint has to be called with </span
        ><span class="c1">Content-Type</span><span>&nbsp;header set to </span
        ><span class="c1">&quot;application/json&quot;</span
        ><span class="c3">.</span>
      </li>
      <li class="c0 li-bullet-0">
        <span class="c3"
          >SafeNow provides a JWT with which the external resource needs to call
          the HTTPS endpoint. The JWT is specific to an external service and
          grants access to one or more SafeNow areas.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span>The HTTPS endpoint has to be called with an </span
        ><span class="c1">Authorization</span
        ><span>&nbsp;header containing the JWT as a bearer token (e.g. </span
        ><span class="c1"
          >&quot;Authorization: Bearer eyJhbGc...31apJA&quot;</span
        ><span class="c3">).</span>
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST contains the </span><span class="c1">name</span
        ><span>, mobile </span><span class="c1">phone</span
        ><span>&nbsp;and </span><span class="c1">email</span
        ><span class="c3"
          >&nbsp;address of the operational manager to contact as
          fallback.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST contains a </span><span class="c1">webhook</span
        ><span class="c3"
          >&nbsp;URL to be called with events happening in the registered
          SafeNow zone.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST optionally contains a set of </span
        ><span class="c1">headers</span
        ><span
          >&nbsp;to be set when calling the WebHook URL of the external
          resource. This allows e.g. setting of </span
        ><span class="c1">Authorization</span
        ><span class="c3"
          >&nbsp;headers if not part of the URL itself and provides external
          resources with more options implementing the service.
        </span>
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST contains </span><span class="c1">capabilities</span
        ><span
          >&nbsp;as an array of predefined strings which can be extended over
          time starting with </span
        ><span class="c1">&quot;ems&quot;</span><span>, </span
        ><span class="c1">&quot;police&quot;</span><span>&nbsp;and </span
        ><span class="c1">&quot;fire-service&quot;</span
        ><span class="c3">.</span>
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST optionally contains the </span
        ><span class="c1">startTime</span><span>&nbsp;and </span
        ><span class="c1">endTime</span><span>&nbsp;in ISO-8601</span
        ><sup><a href="#ftnt1" id="ftnt_ref1">[1]</a></sup
        ><span class="c3"
          >&nbsp;format if registration can be timed by the external resource.
          The default is a registration with an explicit deregistration.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span
          >Registration is allowed at any time even if already done before or
          colliding with prior </span
        ><span class="c1">startTime</span><span>&nbsp;or </span
        ><span class="c1">endTime</span
        ><span class="c3"
          >. The new values overwrite the current ones: Every registration
          resets the registration before.</span
        >
      </li>
    </ul>
    <h2 class="c9" id="h.121f15xwg4hc">
      <span class="c21 c25">Deregistration</span>
    </h2>
    <h4 class="c12" id="h.iwflryf1zxxz"><span class="c17">User Story</span></h4>
    <p class="c4">
      <span class="c3"
        >As an external resource, I need to be able to deregister my service for
        a certain location. After deregistration, I expect SafeNow to not call
        my WebHook again until a new registration is requested.</span
      >
    </p>
    <h4 class="c12" id="h.khuyjx74pf5t">
      <span class="c17">Acceptance Criteria</span>
    </h4>
    <ul class="c28 lst-kix_zh9rzirelwwg-0">
      <li class="c0 li-bullet-0">
        <span
          >SafeNow provides an externally reachable HTTPS endpoint which
          supports DELETE messages. This endpoint has to be called with </span
        ><span class="c1">Content-Type</span><span>&nbsp;header set to </span
        ><span class="c1">&quot;application/json&quot;</span
        ><span class="c3">.</span>
      </li>
      <li class="c0 li-bullet-0">
        <span class="c3"
          >SafeNow provides a JWT with which the external resource needs to call
          the HTTPS endpoint. The JWT is specific to an external service and
          grants access to one or more SafeNow areas.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span>The HTTPS endpoint has to be called with an </span
        ><span class="c1">Authorization</span
        ><span>&nbsp;header containing the JWT as a bearer token (e.g. </span
        ><span class="c1"
          >&quot;Authorization: Bearer eyJhbGc...31ahJA&quot;</span
        ><span class="c3">).</span>
      </li>
      <li class="c0 li-bullet-0">
        <span class="c3"
          >The DELETE request contains no additional data but an empty JSON
          object.
        </span>
      </li>
      <li class="c0 li-bullet-0">
        <span
          >Deregistration is allowed at any time and resets any registration
          done with or without scheduled </span
        ><span class="c1">startTime</span><span>&nbsp;and </span
        ><span class="c1">endTime</span><span class="c3">&nbsp;before.</span>
      </li>
    </ul>
    <h2 class="c9" id="h.m6p7851bpgk">
      <span>Event</span><span class="c21 c25">s</span>
    </h2>
    <h4 class="c12" id="h.ka84i5vkygij"><span class="c17">User Story</span></h4>
    <p class="c4">
      <span class="c3"
        >As an external resource, I need to be able to receive events for a
        certain location. I expect SafeNow to call the WebHook URL provided
        during registration. I also expect that the call is done with the
        optional headers I provided during registration. And I expect a payload
        describing details about case and alarm including at least status and
        location, but contact details would be great, too.
      </span>
    </p>
    <h4 class="c12" id="h.oeic62sm6phu">
      <span class="c17">Acceptance Criteria</span>
    </h4>
    <ul class="c28 lst-kix_zh9rzirelwwg-0">
      <li class="c0 li-bullet-0">
        <span class="c3"
          >The external resource provides an externally reachable HTTPS endpoint
          during registration, which receives commands via POST messages.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c3"
          >The external resource optionally provides HTTP headers for calling
          the HTTPS endpoint (e.g. for authentication and authorization if not
          done via URL).</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST contains an </span><span class="c1">event</span
        ><span>&nbsp;which currently knows the values </span
        ><span class="c1">&quot;create&quot;</span><span>, </span
        ><span class="c1">&quot;escalate&quot;</span><span>&nbsp;, </span
        ><span class="c1">&quot;update&quot;</span><span>, </span
        ><span class="c1">&quot;deescalate&quot;</span><span>&nbsp;and </span
        ><span class="c1">&quot;cancel&quot;</span><span class="c3">.</span>
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST contains a </span><span class="c1">createdAt</span
        ><span class="c3"
          >&nbsp;attribute in ISO-8601 format with the time the event was
          created.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST contains a </span><span class="c1">case</span
        ><span>&nbsp;object with </span><span class="c1">id</span><span>, </span
        ><span class="c1">createdAt</span><span>&nbsp;and </span
        ><span class="c1">modifiedAt</span
        ><span>&nbsp;(both in ISO-8601 format), </span
        ><span class="c1">pssID</span><span>&nbsp;and </span
        ><span class="c1">pssName</span><span>. Attributes </span
        ><span class="c1">id</span><span>&nbsp;and </span
        ><span class="c1">pssID</span><span>&nbsp;are UUIDs (e.g. </span
        ><span class="c1">&quot;65d80695-7e30-45cd-a73d-ec92e0c390fe&quot;</span
        ><span>) and attributes </span><span class="c1">pssID</span
        ><span>&nbsp;and </span><span class="c1">pssName</span
        ><span>&nbsp;define the area where a case is happening.</span>
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST contains a </span><span class="c1">location</span
        ><span>&nbsp;object with at least </span
        ><span class="c1">longitude</span><span>&nbsp;and </span
        ><span class="c1">latitude</span
        ><span>&nbsp;(both double float). The object optionally contains </span
        ><span class="c1">venueName</span><span>, </span
        ><span class="c1">floorNumber</span><span>, </span
        ><span class="c1">roomName</span><span>&nbsp;and </span
        ><span class="c1">subName</span
        ><span class="c3"
          >&nbsp;(all strings) with additional information.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST for events </span
        ><span class="c1">&quot;escalate&quot;</span><span>&nbsp;and </span
        ><span class="c1">&quot;deescalate&quot;</span
        ><span>&nbsp;contain the requested or cancelled </span
        ><span class="c1">capability</span
        ><span>&nbsp;with one of the currently defined values </span
        ><span class="c1">&quot;ems&quot;</span><span>, </span
        ><span class="c1">&quot;police&quot;</span><span>&nbsp;and </span
        ><span class="c1">&quot;fire-service&quot;</span
        ><span class="c3"
          >. Only external resources which registered for a capability will
          receive events for such capability.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST for events &nbsp;</span
        ><span class="c1">&quot;escalate&quot;</span><span>, </span
        ><span class="c1">&quot;deescalate&quot;</span><span>&nbsp;and </span
        ><span class="c1">&quot;update&quot;</span><span>&nbsp;contains a </span
        ><span class="c1">person</span><span>&nbsp;object with at least a </span
        ><span class="c1">personHash</span
        ><span>&nbsp;(MD5 pseudonym of a SafeNow user e.g. </span
        ><span class="c1">&quot;1f6720f956aff055a0d2398aca7cd9ea&quot;</span
        ><span>) and optionally attributes </span
        ><span class="c1">firstName</span><span>, </span
        ><span class="c1">lastName</span><span>, </span
        ><span class="c1">phone</span><span>, </span
        ><span class="c1">email</span><span>&nbsp;and </span
        ><span class="c1">avatarUrl</span
        ><span class="c3">&nbsp;(all strings).</span>
      </li>
      <li class="c0 li-bullet-0">
        <span>The POST for events &nbsp;</span
        ><span class="c1">&quot;escalate&quot;</span><span>, </span
        ><span class="c1">&quot;deescalate&quot;</span><span>&nbsp;and </span
        ><span class="c1">&quot;update&quot;</span><span>&nbsp;contains </span
        ><span class="c1">capabilites</span
        ><span
          >, which is an array of all currently requested capabilities (e.g. </span
        ><span class="c1">[&quot;ems&quot;, &quot;fire-service&quot;]</span
        ><span class="c3">).</span>
      </li>
    </ul>
    <p class="c4 c5"><span class="c3"></span></p>
    <h1 class="c11" id="h.uot4ks5uqqxn"><span class="c18">History</span></h1>
    <p class="c4 c5"><span class="c3"></span></p>
    <a id="t.3edbbc27676827645003a7fc22cd2dfecad41d95"></a><a id="t.0"></a>
    <table class="c26">
      <tbody>
        <tr class="c10">
          <td class="c7" colspan="1" rowspan="1">
            <p class="c2"><span class="c3">2021-02-22</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c2"><span class="c3">Initial draft</span></p>
          </td>
          <td class="c22" colspan="1" rowspan="1">
            <p class="c2"><span class="c3">Jan Ulbrich</span></p>
          </td>
        </tr>
        <tr class="c10">
          <td class="c7" colspan="1" rowspan="1">
            <p class="c2"><span class="c3">2021-03-02</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c2">
              <span class="c3">Updated document with first feedback</span>
            </p>
          </td>
          <td class="c22" colspan="1" rowspan="1">
            <p class="c2"><span class="c3">Jan Ulbrich</span></p>
          </td>
        </tr>
        <tr class="c23">
          <td class="c7" colspan="1" rowspan="1">
            <p class="c2"><span class="c3">2021-06-05</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c2">
              <span>Added missing attribute names for registration (e.g. </span
              ><span class="c1">name</span><span class="c3">). </span>
            </p>
            <p class="c2 c5"><span class="c3"></span></p>
            <p class="c2">
              <span class="c3"
                >Switched to POST for registration and DELETE for
                deregistration.
              </span>
            </p>
            <p class="c2 c5"><span class="c3"></span></p>
            <p class="c2">
              <span>Introduced new event </span
              ><span class="c1">&quot;update&quot;</span
              ><span class="c3"
                >&nbsp;if e.g. location changes or more details about the user
                are available.</span
              >
            </p>
            <p class="c2 c5"><span class="c3"></span></p>
          </td>
          <td class="c22" colspan="1" rowspan="1">
            <p class="c2"><span class="c3">Jan Ulbrich</span></p>
          </td>
        </tr>
        <tr class="c23">
          <td class="c7" colspan="1" rowspan="1">
            <p class="c2"><span class="c3">2021-06-17</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c2">
              <span>Introduced two additional events </span
              ><span class="c1">&quot;create&quot;</span><span>&nbsp;and </span
              ><span class="c1">&quot;cancel&quot;</span
              ><span class="c3"
                >&nbsp;and added missing fields to the event payload.</span
              >
            </p>
          </td>
          <td class="c22" colspan="1" rowspan="1">
            <p class="c2"><span class="c3">Jan Ulbrich</span></p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c4 c5"><span class="c3"></span></p>
    <p class="c14 c5"><span class="c3"></span></p>
    <hr class="c29" />
    <div>
      <p class="c2 c24">
        <a href="#ftnt_ref1" id="ftnt1">[1]</a
        ><span class="c20">&nbsp;See </span
        ><span class="c19"
          ><a
            class="c13"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/ISO_8601&amp;sa=D&amp;source=editors&amp;ust=1623931993038000&amp;usg=AOvVaw1r9--mjsCRE4m-xOLR2Gm_"
            >https://en.wikipedia.org/wiki/ISO_8601</a
          ></span
        ><span class="c20 c21">&nbsp;</span>
      </p>
    </div>

    <!-- HTML export of Google Docs document above -->
  </v-container>
</template>

<script>
export default {
  name: 'Specs',

  data: () => ({}),
};
</script>

<style>
.c15 {
  font-weight: bold;
}
.c1 {
  font-family: monospace;
  font-size: 0.9em;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.3em;
  padding: 0.1em 0.3em;
}

table td {
  vertical-align: top;
}

table td:nth-child(1) {
  width: 20%;
}
table td:nth-child(3) {
  width: 20%;
  text-align: right;
}
</style>
