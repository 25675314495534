<template>
  <v-form align="start" class="mb-4" ref="form" v-model="valid" lazy-validation>
    <h3>Event Basics</h3>

    <v-text-field
      v-model="createdAt"
      type="datetime-local"
      label="Alarm Created At (UTC)"
    ></v-text-field>

    <v-select
      v-model="event"
      :items="availableEvents"
      :rules="requiredRules"
      label="Event"
      required
    ></v-select>

    <v-select
      v-if="['escalate', 'deescalate'].indexOf(event) >= 0"
      v-model="capability"
      :items="availableCapabilities"
      :rules="requiredRules"
      :label="
        event === 'escalate' ? 'Escalated Capability' : 'Deescalated Capability'
      "
      required
    ></v-select>

    <v-select
      v-if="['escalate', 'deescalate', 'update'].indexOf(event) >= 0"
      v-model="capabilities"
      :items="availableCapabilities"
      chips
      label="Already Requested Capabilities"
      multiple
    ></v-select>

    <h3>Case Details</h3>

    <v-text-field
      v-model="caseID"
      :rules="uuidRules"
      :counter="36"
      label="Case ID"
      required
    ></v-text-field>

    <v-text-field
      v-model="caseCreatedAt"
      type="datetime-local"
      label="Case Created At (UTC)"
    ></v-text-field>

    <v-text-field
      v-model="caseModifiedAt"
      type="datetime-local"
      label="Case Modified At (UTC)"
    ></v-text-field>

    <v-text-field
      v-model="pssID"
      :rules="uuidRules"
      :counter="36"
      label="SafeNow Zone ID"
      required
    ></v-text-field>

    <v-text-field
      v-model="pssName"
      :counter="64"
      label="SafeNow Zone Name"
    ></v-text-field>

    <template v-if="['escalate', 'deescalate', 'update'].indexOf(event) >= 0">
      <h3>Person Details</h3>

      <v-text-field
        v-model="personHash"
        :counter="32"
        :rules="md5Rules"
        label="Person Hash"
      ></v-text-field>

      <v-text-field
        v-model="firstName"
        :counter="64"
        :rules="nameRules"
        label="First Name"
      ></v-text-field>

      <v-text-field
        v-model="lastName"
        :counter="64"
        :rules="nameRules"
        label="Last Name"
      ></v-text-field>

      <v-text-field
        v-model="phone"
        :rules="phoneRules"
        label="Phone Number"
      ></v-text-field>

      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
      ></v-text-field>

      <v-text-field
        v-model="avatarUrl"
        :rules="urlRules"
        label="Avatar URL"
      ></v-text-field>
    </template>

    <h3>Location Details</h3>

    <v-text-field
      v-model="longitude"
      :rules="latLongRules"
      label="Longitude"
      required
    ></v-text-field>

    <v-text-field
      v-model="latitude"
      :rules="latLongRules"
      label="Latitude"
      required
    ></v-text-field>

    <v-text-field
      v-model="venueName"
      :counter="64"
      label="Venue Name"
    ></v-text-field>

    <v-text-field
      v-model="floorNumber"
      :counter="16"
      label="Floor"
    ></v-text-field>

    <v-text-field
      v-model="roomName"
      :counter="64"
      label="Room Name"
    ></v-text-field>

    <v-text-field
      v-model="subName"
      :counter="64"
      label="Sub-name"
    ></v-text-field>

    <div class="mt-3">
      <v-btn class="primary" :disabled="loading" @click="send">
        Send Event
      </v-btn>
    </div>
  </v-form>
</template>

<script>
function nowAsDateTimeInput() {
  const now = new Date();

  return now.toISOString().substring(0, 16);
}

export default {
  name: 'EventTester',

  data: () => {
    const defaultDateTime = nowAsDateTimeInput();

    return {
      valid: false,
      loading: false,

      event: 'escalate',
      createdAt: defaultDateTime,

      capability: 'ems',
      capabilities: [],

      caseID: 'd05267ae-243f-1e14-d9dd-5599d993c141',
      caseCreatedAt: defaultDateTime,
      caseModifiedAt: defaultDateTime,

      personHash: '1f6720f956aff055a0d2398aca7cd9ea',
      firstName: 'John',
      lastName: 'Smith',
      phone: '+49 234 56789012',
      email: 'john.smith@acme.com',
      avatarUrl: '',

      pssID: '2443892d-4487-4fbc-9ab1-24c0591233be',
      longitude: 11.602718532085419,
      latitude: 48.11895144867552,
      pssName: 'SafeNow GmbH',
      venueName: 'SafeNow GmbH',
      floorNumber: '3',
      roomName: 'Kitchen',
      subName: '',

      availableEvents: ['create', 'escalate', 'deescalate', 'update', 'cancel'],
      availableCapabilities: ['ems', 'police', 'fire-service'],

      emailRules: [],
      latLongRules: [
        (value) => {
          const pattern = /^-?[0-9]{1,3}(\.[0-9]{1,16}|)$/;
          return pattern.test(value) || 'Invalid coordinate';
        },
        (value) => {
          const f = parseFloat(value);
          return (f >= -180 && f <= 180) || 'Invalid coordinate';
        },
      ],
      md5Rules: [
        (value) => {
          const pattern = /^[0-9a-f]{32}$/;
          return pattern.test(value) || 'Invalid MD5 hash';
        },
      ],
      nameRules: [],
      phoneRules: [],
      requiredRules: [(value) => !!value || 'Required'],
      urlRules: [
        (value) => {
          const pattern = /^https?:\/\/.*$/;
          return pattern.test(value) || 'Invalid HTTP URL';
        },
      ],
      uuidRules: [
        (value) => {
          const pattern = /^[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}$/;
          return pattern.test(value) || 'Invalid UUID';
        },
      ],
    };
  },

  methods: {
    send() {
      const eventData = {
        event: this.event,
        createdAt: `${this.createdAt}:00.000Z`,

        capability: this.capability,
        capabilities: this.capabilities,

        case: {
          id: this.caseID,
          caseCreatedAt: `${this.caseCreatedAt}:00.000Z`,
          caseModifiedAt: `${this.caseModifiedAt}:00.000Z`,
          pssID: this.pssID,
          pssName: this.pssName,
        },

        person: {
          personHash: this.personHash,
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
          avatarUrl: this.avatarUrl,
        },

        location: {
          longitude: this.longitude,
          latitude: this.latitude,
          venueName: this.venueName,
          floorNumber: this.floorNumber,
          roomName: this.roomName,
          subName: this.subName,
        },
      };

      this.loading = true;

      this.$_sendEvent(eventData).then(
        (response) => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.caseModifiedAt = nowAsDateTimeInput();
        },
      );
    },
  },
};
</script>
