<template>
  <v-container>
    <!-- HTML export of README below -->

    <p>
      This is a reference implementation of the Webhook API of SafeNow. It
      supports the requests described in the specification and provides a test
      interface for triggering events. See the "Specs" tab for details or open
      the Google Docs version of the specification
      <a
        href="https://docs.google.com/document/d/1nGBCu_z4rM8ny3yJLZz22fRUnaUuZSOec_plRMCbm60/edit#heading=h.hcomra42myoq"
        target="_new"
        >here</a
      >
      for details.
    </p>

    <h2>Example Requests</h2>

    <p>
      While the "Event Tester" tab will help you with triggering test events,
      the registration and deregistration of webhooks doesn't have a visual
      interface. See below for example calls using <code>curl</code>.
    </p>

    <p>
      Requests need to set the <code>Content-Type</code> to
      <code>application/json</code> and provide a <code>Authorization</code>
      header with a bearer token.
    </p>

    <p>
      Here's a token valid for this test server if you start it without changing
      the default secret:
    </p>

    <p>
      <code
        >eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJTYWZlTm93IEdtYkgiLCJzdWIiOiJ3ZWJob29rIiwiaWF0IjoxNjIyODQ0MDAwLCJuYmYiOjE2MjI4NDQwMDAsImV4cCI6MTY1NDM4MDAwMCwicHNzSURzIjpbIjI0NDM4OTJkLTQ0ODctNGZiYy05YWIxLTI0YzA1OTEyMzNiZSJdfQ.KS8c9StXf5xRQ8qfkTBdgUK9eNhFsGp3WmWwJauSw_045d2dH_TJ0S1tK_q5ZSdA-hZ3u21zWAJaptAp4-MVCg</code
      >
    </p>

    <h3>Registration</h3>

    <p>
      Registration is done with a <code>POST</code> containing the webhook URL
      and contact information about the partner. This contact information is
      used if there are technical issues.
    </p>

    <p>
      An optional set of headers can be provided in the payload (see
      <code>"headers"</code> below) and they will be used when calling the
      webhook URL. This can be used to set certain content types or pass
      authorization information.
    </p>

    <p>
      Optional parameters <code>"startTime"</code> and
      <code>"endTime"</code> can be used to define when a shift is on duty. The
      registration can be called repeatedly to update these details.
    </p>

    <p>
      <strong>Attention:</strong> If you're running the server with Docker,
      please keep in mind, that something like
      <code>http://localhost:9999/</code> is not available from the webhook
      server. Registration will work, but no event will be delivered.
    </p>

    <pre>
curl \
  -H 'Content-Type: application/json' \
  -H 'Authorization: Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJTYWZlTm93IEdtYkgiLCJzdWIiOiJ3ZWJob29rIiwiaWF0IjoxNjIyODQ0MDAwLCJuYmYiOjE2MjI4NDQwMDAsImV4cCI6MTY1NDM4MDAwMCwicHNzSURzIjpbIjI0NDM4OTJkLTQ0ODctNGZiYy05YWIxLTI0YzA1OTEyMzNiZSJdfQ.KS8c9StXf5xRQ8qfkTBdgUK9eNhFsGp3WmWwJauSw_045d2dH_TJ0S1tK_q5ZSdA-hZ3u21zWAJaptAp4-MVCg' \
  -X POST \
  -d '{ "name": "Jane Doe",
    "phone": "+49 123 45678901",
    "email": "jane.doe@h3g243.ngrok.io",
    "capabilities": ["ems"],
    "startTime": "2021-05-16T06:00:00.000Z",
    "endTime": "2021-05-16T20:00:00.000Z",
    "headers": {"foo":"bar"},
    "webhook": "https://acme.com/foo/bar"
  }' \
  {{ baseURL }}/api/v1/webhooks
</pre
    >

    <h3>Deregistration</h3>

    <p>
      Deregistration of a webkhook needs no additional parameters but a valid
      JSON object.
    </p>

    <pre>
curl \
  -H 'Content-Type: application/json' \
  -H 'Authorization: Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJTYWZlTm93IEdtYkgiLCJzdWIiOiJ3ZWJob29rIiwiaWF0IjoxNjIyODQ0MDAwLCJuYmYiOjE2MjI4NDQwMDAsImV4cCI6MTY1NDM4MDAwMCwicHNzSURzIjpbIjI0NDM4OTJkLTQ0ODctNGZiYy05YWIxLTI0YzA1OTEyMzNiZSJdfQ.KS8c9StXf5xRQ8qfkTBdgUK9eNhFsGp3WmWwJauSw_045d2dH_TJ0S1tK_q5ZSdA-hZ3u21zWAJaptAp4-MVCg' \
  -X DELETE \
  -d '{ }' \
  {{ baseURL }}/api/v1/webhooks
</pre
    >

    <!-- HTML export of README above -->
  </v-container>
</template>

<script>
export default {
  name: 'Specs',

  data: () => ({
    baseURL: window.location.origin,
  }),
};
</script>

<style>
.c15 {
  font-weight: bold;
}
.c1 {
  font-family: monospace;
  font-size: 0.9em;
  color: #700;
}

table td {
  vertical-align: top;
}

table td:nth-child(1) {
  width: 20%;
}
table td:nth-child(3) {
  width: 20%;
  text-align: right;
}
</style>
